import React from 'react';
import Layout from '../Layout';
import OrganizationSchema from '../OrganizationSchema';
import { Helmet } from 'react-helmet';
import Breadcrumbs from '../Breadcrumbs';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FaCheckCircle } from 'react-icons/fa';

const ServiceTemplate = ({
  title,
  subtitle,
  description,
  icon: Icon,
  seo,
  breadcrumbs,
  benefits,
  flow,
  preparation,
  works,
  price,
  cta,
}) => {
  return (
    <Layout>
      {/* ✅ Organization Schema の構造化データを読み込む */}
      <OrganizationSchema />

      {/* ✅ Helmet に SEO データを適用 */}
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={seo.pageUrl} />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.pageUrl} />
        <meta property="og:image" content={seo.ogImage} />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.ogImage} />
      </Helmet>
      <div className="max-w-screen-xl mx-auto px-8 bg-white bg-opacity-90 rounded-2xl shadow-xl my-24 py-20">
        {/* タイトル */}
        <h1 className="text-6xl font-bold text-black text-center mb-4">
          {title}
        </h1>
        <h2 className="text-3xl md:text-4xl text-gray-600 text-center mb-10">
          {subtitle}
        </h2>

        {/* ✅ React Icon を挿入（ページごとに異なるアイコンを指定可能） */}
        {Icon && (
          <div className="flex justify-center mb-10">
            <Icon className="text-7xl text-black" />
          </div>
        )}

        {/* 概要 */}
        <p className="text-2xl text-gray-700 mt-6 text-center px-8">
          {description}
        </p>

        {/* 3つのメリット */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 my-16">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="p-10 bg-white shadow-lg rounded-lg flex flex-col justify-between h-full"
            >
              <span className="text-4xl font-extrabold text-gray-900 mr-2">
                {String(index + 1).padStart(2, '0')}.
              </span>
              <h3 className="text-3xl font-bold text-black mb-3">
                {benefit.title}
              </h3>
              <p className="text-2xl text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>

        {/* 制作の流れ */}
        <div className="mb-16 my-24 py-20 px-8 text-center bg-gray-100">
          <h2 className="text-3xl font-bold mb-8 text-center text-black border-b-4 border-black pb-4 inline-block">
            <span className="font-roboto text-8xl font-bold block">Flow</span>
            制作の流れ
          </h2>
          <div className="relative">
            {flow.map((step, index) => (
              <div
                key={index}
                className={`relative mb-16 p-8 rounded-lg shadow-lg ${
                  index % 2 === 0 ? 'bg-gray-300' : 'bg-gray-200'
                }`}
              >
                {/* 🔥 番号・タイトル・期間を横並びに調整 */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <span className="text-3xl font-bold text-black">
                      {String(index + 1).padStart(2, '0')}
                    </span>
                    <h3 className="text-2xl font-bold text-black">
                      {step.title}
                    </h3>
                  </div>
                  {step.duration && (
                    <span className="text-xl bg-black text-white px-3 py-1 rounded-full">
                      {step.duration}
                    </span>
                  )}
                </div>
                <p className="text-2xl text-left text-gray-700 mt-4">
                  {step.description}
                </p>
                {/* 矢印（次のステップがある場合のみ表示） */}
                {index !== flow.length - 1 && (
                  <div className="absolute left-1/2 transform -translate-x-1/2 bottom-[-40px] text-gray-500 text-3xl">
                    ↓
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* 事前準備 */}
        <div className="my-24 py-20 text-center bg-white">
          <h2 className="text-3xl font-bold mb-8 text-center text-black border-b-4 border-black pb-4 inline-block">
            <span className="font-roboto text-8xl font-bold block">
              Checklist
            </span>
            ご確認いただく事項
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {preparation.map((item, index) => (
              <div key={index} className="p-6 bg-white shadow-lg rounded-lg">
                <h3 className="text-2xl text-left font-bold text-black mb-3 flex items-center gap-2 whitespace-nowrap">
                  <FaCheckCircle className="text-green-500 text-xl flex-shrink-0" />
                  <span className="truncate">{item.title}</span>
                </h3>
                <p className="text-2xl text-left text-gray-600">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* 施工実績（WORKS） */}
        {works && works.length > 0 && (
          <div className="mb-16 my-24 py-20 text-center">
            <h2 className="text-3xl font-bold mb-8 text-center text-black border-b-4 border-black pb-4 inline-block">
              <span className="font-roboto text-8xl font-bold block">
                Works
              </span>
              制作実績
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {works.slice(0, 3).map((work, index) => (
                <div key={index} className="p-4 bg-white shadow-lg rounded-lg">
                  {work.image ? (
                    <GatsbyImage
                      image={work.image}
                      alt={work.title}
                      className="w-full rounded-lg"
                    />
                  ) : (
                    <div
                      className="w-full bg-gray-300 rounded-lg flex items-center justify-center"
                      style={{ height: '200px' }}
                    >
                      <p className="text-gray-600">画像なし</p>
                    </div>
                  )}
                  <h3 className="text-2xl font-bold text-black mt-4">
                    {work.title}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="text-center mt-16">
          <a
            href="/works"
            className="inline-block border border-black text-2xl font-bold text-black py-4 px-10 rounded-xl transition duration-300 hover:bg-black hover:text-white"
          >
            他の制作実績を見る
          </a>
        </div>

        {/* 🔥 料金 */}
        <div className="mb-16 my-24 py-20 text-center bg-gray-100 rounded-xl shadow-lg px-8">
          {/* ✅ 中央揃えを flex で適用し、下線を適切な幅に調整 */}
          <div className="flex justify-center">
            <h2 className="text-3xl font-bold mb-12 text-center text-black border-b-4 border-black pb-4 inline-block">
              <span className="font-roboto text-8xl font-bold block">
                Price
              </span>
              料金
            </h2>
          </div>
          {/* ✅ 料金ボックス */}
          <div className="bg-gray-200 text-black text-6xl font-bold py-10 px-16 rounded-lg inline-block shadow-md">
            {price}
          </div>
          {/* ✅ 補足説明 */}
          <p className="text-xl text-gray-700 mt-4">
            ※ 価格はサイトの規模や機能によって変動します。
            <br />
            詳細な見積もりについては、お気軽にご相談ください。
          </p>
        </div>

        {/* CTA（お問い合わせボタン） */}

        <div className="text-center mt-16">
          <a
            href="/contact"
            className="inline-block border border-black text-2xl font-bold text-white bg-black py-5 px-12 rounded-xl shadow-lg transition duration-300 hover:bg-gray-800"
          >
            {cta}
          </a>
        </div>
      </div>
      <Breadcrumbs breadcrumbList={breadcrumbs} />
    </Layout>
  );
};

export default ServiceTemplate;
