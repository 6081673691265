import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaCogs } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: 'CMSカスタマイズ｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'WordPressやヘッドレスCMSを活用し、運用しやすい管理画面を構築します。ビジネスのニーズに合わせてカスタマイズし、更新作業の負担を軽減。直感的に操作できるCMSで、スムーズな運用と管理を実現します。',
    pageUrl: 'https://www.involve-in.jp/service/cms',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'CMSカスタマイズ', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="CMSカスタマイズ"
        subtitle="WordPress・ヘッドレスCMS"
        icon={FaCogs}
        description="WordPressやヘッドレスCMSを活用し、運用しやすい管理画面を構築します。ビジネスのニーズに合わせてカスタマイズし、更新作業の負担を軽減。直感的に操作できるCMSで、スムーズな運用と管理を実現します。"
        seo={{
          title: 'CMSカスタマイズ｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            'WordPressやヘッドレスCMSを活用し、運用しやすい管理画面を構築します。ビジネスのニーズに合わせてカスタマイズし、更新作業の負担を軽減。直感的に操作できるCMSで、スムーズな運用と管理を実現します。',
          pageUrl: 'https://www.involve-in.jp/service/cms',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'CMSカスタマイズ',
            url: 'https://www.involve-in.jp/service/cms',
          },
        ]}
        benefits={[
          {
            title: '直感的な管理画面',
            description:
              'カスタムフィールドやブロックエディターを活用し、誰でも簡単に編集・更新できる管理画面を構築。',
          },
          {
            title: 'セキュリティ対策',
            description:
              'ログイン強化、定期バックアップ、最新環境での動作確認を行い、安全で信頼性の高いCMS環境を提供。',
          },
          {
            title: 'スピード最適化',
            description:
              'ヘッドレスCMSやキャッシュ技術を活用し、高速なページ表示を実現し、ユーザー体験を向上。',
          },
        ]}
        flow={[
          {
            title: '要件定義',
            duration: '1週間',
            description:
              'CMSの機能要件を整理し、最適なシステムを設計。業種に合わせた情報入力の補助機能を構築し、運用のしやすさを向上。',
          },
          {
            title: '開発・カスタマイズ',
            duration: '2週間',
            description:
              'WordPressやヘッドレスCMSのカスタマイズを実施。特にWordPress開発は10年以上の経験があり、最適な手法で構築。',
          },
          {
            title: 'テスト・調整',
            duration: '1週間',
            description:
              '動作テストとパフォーマンス改善を実施。テスト環境を用意し、実際の運用を想定した動作確認を行いながら調整。',
          },
          {
            title: '納品・運用開始',
            duration: '1週間',
            description:
              '最終チェックを行い、本番環境でシステムを公開。スムーズな運用ができるよう、必要に応じてサポートも提供。',
          },
        ]}
        preparation={[
          {
            title: '管理者の操作性確認',
            description:
              'CMSの管理画面で必要な機能を洗い出し、使いやすさを最適化。こちらからのご提案も可能です。',
          },
          {
            title: 'セキュリティ対策の方針決定',
            description:
              'アクセス制限、ログイン強化、バックアップの仕組みを事前に検討し、安全な運用環境を構築。',
          },
          {
            title: '運用フローの整理',
            description:
              '記事投稿や更新作業の流れを明確にし、スムーズな運用ができるようにサポート。',
          },
          {
            title: 'CMSでしたいことの確認',
            description:
              'どのような目的でCMSを導入・カスタマイズしたいのかをヒアリングし、最適な設計を提案。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥250,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
